import React from 'react';
import { string, shape } from 'prop-types';

import IconFilter from '../../icons/toolbar/filter';
import Link from '../../link';

/* @TODO refactor list collapsible */
const AvailableFiltersMobile = ({ labels, url }) => (
  <Link href={url} isInternal className="filter-option-menu--link" title={labels.text}>
    <IconFilter />
    {labels.filter || labels.text}
  </Link>
);

AvailableFiltersMobile.propTypes = {
  labels: shape({}).isRequired,
  url: string.isRequired,
};

export default AvailableFiltersMobile;
