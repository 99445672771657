import React from 'react';
import { shape, string, arrayOf } from 'prop-types';

import classnames from 'classnames';

import AvailableFilters from '../../filters/available/available-filters.mobile';
import TextCarousel from './text-carousel.mobile';
import ColorCarousel from './color-carousel.mobile';
import SpecializedCarousel from './specialized-carousel.mobile';
import { FILTER_INTERVENTION } from '../../../constants';

const namespace = 'ui-search-filter-intervention';

const FilterIntervention = ({ intervention }) => {
  const { type, title, filter, background_color } = intervention;

  const isTopBrand = filter.id === 'BRAND' && type === 'FILTER_SPECIALIZED';

  // Se mapean los filtros expandidos en el formato que espera AvailableFilters
  const mapFilter = (titleMap, filterMap) => [
    {
      id: filterMap.id,
      name: titleMap.text,
      values: [],
      showModal: false,
      expanded_values: filterMap.map((value) => ({
        name: value.label.text,
        url: value.target,
      })),
    },
  ];

  const renderIntervention = (filterValues) => {
    switch (type) {
      case FILTER_INTERVENTION.TYPES.FILTER_COLOR:
        return <ColorCarousel filter={filterValues} />;
      case FILTER_INTERVENTION.TYPES.FILTER_SPECIALIZED:
        return (
          <SpecializedCarousel
            filter={filterValues}
            title={title}
            isTopBrand={isTopBrand}
            availableFilters={filter.expanded_values && mapFilter(title, filter.expanded_values)}
          />
        );

      default:
        return <TextCarousel filter={filterValues} />;
    }
  };

  return (
    <div className={classnames(namespace, `${namespace}-container`, `${namespace}-container__${type}`)}>
      <div
        className={classnames(
          `${namespace}-background`,
          background_color ? `${namespace}-${background_color}` : `${namespace}-gray-040-solid`,
        )}
      >
        <span className={`${namespace}-title`}>
          {filter?.see_all_title?.text}
          <span className={`${namespace}-title__SEMIBOLD`}> {title.text}</span>
        </span>
        {filter.see_all_text && filter.expanded_values && filter.expanded_values.length > 0 && (
          <span className={`${namespace}-view-all`}>
            <AvailableFilters
              title={filter.see_all_title.text}
              labels={filter.see_all_text}
              icon={false}
              filters={mapFilter(title, filter.expanded_values)}
              isTopBrand={isTopBrand}
              isExpanded
            />
          </span>
        )}
        {renderIntervention(filter)}
      </div>
    </div>
  );
};

FilterIntervention.propTypes = {
  intervention: shape({
    filter: shape({
      see_all_title: shape({
        text: string,
      }),
      see_all_text: shape(),
      expanded_values: arrayOf(shape()),
    }).isRequired,
    title: shape({
      text: string,
    }).isRequired,
    type: string.isRequired,
  }),
};

export default FilterIntervention;
