import React from 'react';
import { shape, bool, arrayOf } from 'prop-types';

import classnames from 'classnames';
import Image from 'nordic/image';
import Thumbnail from '@andes/thumbnail';
import CarouselFree, { CarouselFreeSlide } from '@andes/carousel-free';

import AvailableFilters from '../../filters/available/available-filters.mobile';
import Link from '../../link';
import StyledLabelWrapper from '../../styled-label/styled-label';

const namespace = 'ui-search-filter-intervention';
const BRAND_TYPE = 'BRAND';
const isTitleOneLine = (title) => title.indexOf(' ') === -1;
const SEE_MORE = 'see_more';

const SpecializedCarousel = ({ filter, title, isTopBrand, availableFilters }, index) => {
  const getThumbnailSize = (thumbnailSize) => (filter.id === BRAND_TYPE ? thumbnailSize || 64 : 80);

  const renderSlides = (slide) => {
    if (slide.id !== SEE_MORE) {
      return (
        <CarouselFreeSlide key={slide.id} className={`${namespace}-specialized-item-slide`}>
          <Link
            className={`${namespace}__link`}
            href={slide.target && slide.target}
            filterId={slide.id}
            title={slide.picture.tags.alt}
          >
            <Thumbnail size={Number(getThumbnailSize(slide.thumbnail_size))}>
              <Image alt="" lazyload={index < 4 ? 'off' : 'on'} preload={index < 4} src={slide.picture.normal} />
            </Thumbnail>
            <StyledLabelWrapper
              className={classnames(
                `${namespace}${
                  isTitleOneLine(slide.label.text) ? '-specialized-title-short' : '-specialized-title-large'
                }`,
              )}
              color={slide.label.color}
              font_weight={slide.label.font_family}
              size={slide.label.font_size}
              text={slide.label.text}
              as="span"
            />
            {slide.subtitle && <span className={`${namespace}-specialized-subtitle`}>{slide.subtitle.text}</span>}
          </Link>
        </CarouselFreeSlide>
      );
    }

    return (
      <CarouselFreeSlide key={slide.id} className={`${namespace}-specialized-item-slide`}>
        <Thumbnail size={Number(getThumbnailSize(slide.thumbnail_size))}>
          <Image
            alt={slide.picture.tags.alt}
            lazyload={index < 4 ? 'off' : 'on'}
            preload={index < 4}
            src={slide.picture.normal}
          />
        </Thumbnail>
        {slide.subtitle && <span className={`${namespace}-specialized-subtitle`}>{slide.subtitle.text}</span>}
        <AvailableFilters
          title={title.text}
          labels={slide.label}
          icon={false}
          filters={availableFilters}
          isTopBrand={isTopBrand}
          isExpanded
          srLabel={title || ''}
        />
      </CarouselFreeSlide>
    );
  };

  return (
    <CarouselFree spacing={12} className={`${namespace}-carousel`}>
      {filter.values.map((slide) => renderSlides(slide))}
    </CarouselFree>
  );
};

SpecializedCarousel.propTypes = {
  availableFilters: arrayOf(shape()),
  filter: shape().isRequired,
  isTopBrand: bool,
  title: shape(),
};

export default SpecializedCarousel;
