import React from 'react';
import { shape, string, arrayOf } from 'prop-types';

import classnames from 'classnames';

import SpecializedCarousel from './specialized-carousel.desktop';
import { FILTER_INTERVENTION } from '../../../constants';

const namespace = 'ui-search-filter-intervention';

const FilterIntervention = ({ intervention, deviceType }) => {
  const { type, title, filter } = intervention;

  const renderIntervention = (filterValues) => {
    switch (type) {
      case FILTER_INTERVENTION.TYPES.FILTER_SPECIALIZED:
        return <SpecializedCarousel type={type} filter={filterValues} title={title} deviceType={deviceType} />;

      default:
        return null;
    }
  };

  return (
    <div className={classnames(namespace, `${namespace}-container`, `${namespace}-container__${type}`)}>
      {renderIntervention(filter)}
    </div>
  );
};

FilterIntervention.propTypes = {
  deviceType: string,
  intervention: shape({
    filter: shape({
      see_all_title: shape({
        text: string,
      }),
      see_all_text: shape(),
      expanded_values: arrayOf(shape()),
    }).isRequired,
    title: shape({
      text: string,
    }).isRequired,
    type: string.isRequired,
  }),
};

export default FilterIntervention;
