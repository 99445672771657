import React, { useContext } from 'react';
import { shape, string } from 'prop-types';

import classnames from 'classnames';
import { Image } from 'nordic/image';
import { Thumbnail } from '@andes/thumbnail';
import { CarouselSnapped, CarouselSnappedSlide } from '@andes/carousel-snapped';

import Link from '../../link';
import StyledLabelWrapper from '../../styled-label/styled-label';
import useModal from '../../modal/hooks/use-modal';
import Modal from '../../modal/base.mobile';
import SearchModalContainer from '../../sidebar/components/search-modal/search-modal.desktop';
import useCarouselBreakpoints from '../../../hooks/use-carousel-breakpoints';
import { LayoutContext } from '../../context/layout';

const namespace = 'ui-search-filter-intervention';
const isTitleOneLine = (title) => title.indexOf(' ') === -1;

const SpecializedCarousel = ({ filter, title, deviceType }) => {
  if (filter.see_all_text) {
    filter.values.push(filter.see_all_text);
    filter.see_all_text = null;
  }

  const { currentLayout } = useContext(LayoutContext);
  const totalSlides = filter.values.length;
  const hasNineSlides = totalSlides === 9;
  const sevenSlides = 7;
  const { slidesToShow } = useCarouselBreakpoints(
    'BRANDS',
    currentLayout?.id,
    hasNineSlides ? sevenSlides : totalSlides,
  );
  const [isOpen, openModal, hideModal] = useModal();
  const isTablet = deviceType === 'tablet';
  const slidesToShowWhenTabletDevice = 4;

  const renderSlides = (slide, index) => {
    if (slide.id !== 'see_more') {
      return (
        <CarouselSnappedSlide key={`${index}${slide.label.text}`} className={`${namespace}-specialized-item-slide`}>
          <Link className={`${namespace}__link`} href={slide.target} filterId={slide.id} title={slide.picture.tags.alt}>
            <Thumbnail size="80">
              <Image alt="" lazyload={index < 7 ? 'off' : 'on'} preload={index < 7} src={slide.picture.normal} />
            </Thumbnail>
            <StyledLabelWrapper
              className={classnames(
                `${namespace}${
                  isTitleOneLine(slide.label.text) ? '-specialized-title-short' : '-specialized-title-large'
                }`,
              )}
              color={slide.label.color}
              font_weight={slide.label.font_family}
              size={slide.label.font_size}
              text={slide.label.text}
              as="span"
            />
            {slide.subtitle && <span className={`${namespace}-specialized-subtitle`}>{slide.subtitle.text}</span>}
          </Link>
        </CarouselSnappedSlide>
      );
    }

    return (
      <>
        <CarouselSnappedSlide className={`${namespace}-specialized-item-slide`}>
          {filter.expanded_values && filter.expanded_values.length > 0 && (
            <button type="button" onClick={openModal} className={`${namespace}-specialized-see-more`}>
              <Thumbnail size="80">
                <Image
                  alt={slide.picture.tags.alt}
                  lazyload={index < 7 ? 'off' : 'on'}
                  preload={index < 7}
                  src={slide.picture.normal}
                />
              </Thumbnail>
              <StyledLabelWrapper
                className={classnames(`${namespace}-specialized-title-short`)}
                color={slide.label.color}
                font_weight={slide.label.font_family}
                size={slide.label.font_size}
                text={slide.label.text}
                as="span"
              />
            </button>
          )}
        </CarouselSnappedSlide>
        <div className={`${namespace}-specialized-see-more-modal`}>
          <Modal
            className="ui-search-modal--default"
            modalUrl="#filter"
            type="small"
            withLink={false}
            open={isOpen}
            openModal={openModal}
            hideModal={hideModal}
            modalTitle={title.text}
          >
            <SearchModalContainer values={filter.expanded_values} id={filter.id} type="WITH_SEARCH" {...filter.modal} />
          </Modal>
        </div>
      </>
    );
  };

  return (
    <CarouselSnapped
      pagination={false}
      className={`${namespace}-carousel`}
      slidesPerView={isTablet ? slidesToShowWhenTabletDevice : slidesToShow}
      spacing={16}
      strictBoundaries={false}
    >
      {filter.values.map((slide, index) => renderSlides(slide, index))}
    </CarouselSnapped>
  );
};

SpecializedCarousel.propTypes = {
  deviceType: string,
  filter: shape().isRequired,
  title: shape({}).isRequired,
};

export default SpecializedCarousel;
